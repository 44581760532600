.adm-floating-bubble {
  --initial-position-left: var(--initial-position-left);
  --initial-position-right: var(--initial-position-right);
  --initial-position-top: var(--initial-position-top);
  --initial-position-bottom: var(--initial-position-bottom);
  --z-index: 1;
  --edge-distance: 0;
  --size: 48px;
  --border-radius: 50%;
  --background: var(--adm-color-primary);
}
.adm-floating-bubble-boundary-outer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: var(--edge-distance);
  box-sizing: border-box;
  pointer-events: none;
}
.adm-floating-bubble-boundary {
  position: relative;
  width: 100%;
  height: 100%;
}
.adm-floating-bubble-button {
  position: fixed;
  top: var(--initial-position-top);
  bottom: var(--initial-position-bottom);
  left: var(--initial-position-left);
  right: var(--initial-position-right);
  box-sizing: border-box;
  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  transition: opacity ease 0.15s;
  -webkit-user-select: none;
          user-select: none;
  touch-action: none;
  background: var(--background);
  color: var(--adm-color-white);
  border-radius: var(--border-radius);
  z-index: var(--z-index);
}
