@stepsPrefixCls: am-steps;
@errorTailCls: error-tail;
@defaultIconSize: 22 * @hd;
@smallIconSize: 18 * @hd;
@stepDescriptionMaxWidth: 100 * @hd;
@iconPrefixCls: am;

// 对应 4 种状态的颜色变量，用在自定义函数 .step-item-status(@status) 上
@process-icon-color: @brand-primary;
@process-title-color: @color-text-base;
@process-description-color: @process-title-color;
@process-tail-color: @border-color-base;

@wait-icon-color: @color-icon-base;
@wait-title-color: @color-text-base;
@wait-description-color: @wait-title-color;
@wait-tail-color: @border-color-base;

@finish-icon-color: @brand-primary;
@finish-title-color: @color-text-base;
@finish-description-color: @finish-title-color;
@finish-tail-color: @process-icon-color;

@error-icon-color: @brand-error;
@error-title-color: @error-icon-color;
@error-description-color: @error-icon-color;
@error-tail-color: @process-tail-color;
