.adm-image-viewer-content {
  width: 100vw;
  height: 100vh;
  touch-action: none;
  -webkit-user-select: none;
          user-select: none;
}
.adm-image-viewer-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
}
.adm-image-viewer-slides {
  height: 100%;
  position: relative;
  z-index: 1;
  cursor: -webkit-grab;
  cursor: grab;
  touch-action: none;
}
.adm-image-viewer-slides-inner {
  height: 100%;
  white-space: nowrap;
}
.adm-image-viewer-slides-inner > * {
  margin-right: 16px;
}
.adm-image-viewer-slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.adm-image-viewer-control {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  touch-action: none;
}
.adm-image-viewer-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.adm-image-viewer-image-wrapper img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.adm-image-viewer-indicator {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  color: var(--adm-color-border);
  font-size: var(--adm-font-size-6);
}
